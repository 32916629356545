<template>
  <div class="dev-panel">
    <h1 class="text-h5 grey--text text-uppercase">Dev Panel</h1>
    <v-row class="my-4">
      <v-col cols="12">
        <v-btn color="primary" :loading="internalLoading" disabled @click="fixAudiDataCut()">
          Run audioData Cut Fix
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn
          color="primary"
          :loading="internalLoading"
          :disabled="!hasDuplicatedItems"
          @click="fixDuplicatedFiles()"
        >
          Fix Duplicated Files
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn color="primary" :loading="internalLoading" @click="changeCensureCampaign()">
          Change centures campaign
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn color="primary" :loading="internalLoading" @click="doChangeCampaignData()">
          Change agency name in Campaign Data
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  db,
  collection,
  query,
  getDocs,
  updateDoc,
  doc,
  storage,
  ref,
  listAll,
  getMetadata,
  deleteObject,
} from "@/firebase/firebase";

export default {
  name: "DevPanel",

  components: {
    //
  },

  mounted() {
    this.getAllDuplicatedFiles();
  },

  data() {
    return {
      internalLoading: false,
      censureData: [],
      problemCensures: [],
      duplicatedItems: [],
      hasDuplicatedItems: false,
    };
  },

  computed: {
    //
  },

  watch: {
    duplicatedItems(val) {
      if (val && val.length) this.hasDuplicatedItems = true;
    },
  },

  methods: {
    async doChangeCampaignData() {
      this.internalLoading = true;
      const campaignId = "2023-11-regional-ne";
      const oldAgencyName = "NACIONAL";
      const newAgencyName = "NOVA SB";

      try {
        // change radiodata agency name
        await this.$store.dispatch("loadCampaignWithCensuresData", campaignId);
        const campaign = await this.$store.getters.getCampaign;
        campaign.radiosData.forEach((obj) => {
          if (obj.agencia === oldAgencyName) obj.agencia = newAgencyName;
          delete obj.id;
        });
        await updateDoc(doc(db, "campaigns", campaignId), {
          radiosData: campaign.radiosData,
        });

        // change censureData
        campaign.censuresData.forEach(async (obj) => {
          if (obj.agencia === oldAgencyName) {
            obj.agencia = newAgencyName;
            const censureId = obj.id;
            await updateDoc(doc(db, "campaigns", campaignId, "censuresData", censureId), {
              agencia: obj.agencia,
            });
          }
        });
      } catch (err) {
        console.log(err);
      }

      this.internalLoading = false;
    },

    async fixDuplicatedFiles() {
      this.internalLoading = true;

      if (this.hasDuplicatedItems) {
        this.duplicatedItems.sort((a, b) => this.compareText(a, b, "folder"));
        const keys = [];
        const groupedItems = {};
        this.duplicatedItems.forEach((obj) => {
          const newItem = { ...obj };
          if (!keys.includes(obj.folder)) {
            keys.push(obj.folder);
            groupedItems[obj.folder] = [newItem];
          } else {
            groupedItems[obj.folder].push({ ...newItem });
          }
        });

        keys.forEach(async (key) => {
          const objA = groupedItems[key][0];
          const objB = groupedItems[key][1];
          let objToDelete = null;
          if (objA.generation > objB.generation) objToDelete = objB;
          else objToDelete = objA;
          try {
            const censureFileRef = ref(storage, objToDelete.fullPath);
            await deleteObject(censureFileRef);
          } catch (err) {
            console.error(err);
          }
          console.log("deleted: ", key + " => " + objToDelete.name);
        });
      } else {
        console.log("All fixed");
      }
      this.internalLoading = false;
    },

    compareText(a, b, field) {
      const textA = a[field].toUpperCase();
      const textB = b[field].toUpperCase();
      if (textA < textB) return -1;
      if (textA > textB) return 1;
      return 0;
    },

    async getAllDuplicatedFiles() {
      const listRef = ref(storage, "/campaigns/30-anos-sest-senat/audios");

      try {
        const resp = await listAll(listRef);
        resp.prefixes.forEach(async (folderRef) => {
          const subFolderRef = ref(storage, folderRef.fullPath);
          const subRes = await listAll(subFolderRef);
          if (subRes.items.length > 1) {
            subRes.items.forEach(async (itemRef) => {
              // console.log(itemRef.parent.name + ' => ' + itemRef.name);
              const newStoreObj = {
                folder: itemRef.parent.name,
                name: itemRef.name,
                fullPath: itemRef.fullPath,
              };
              const fileRef = await ref(storage, itemRef.fullPath);
              const metaData = await getMetadata(fileRef);
              // console.log(metaData);
              newStoreObj.generation = metaData.generation;
              this.duplicatedItems.push(newStoreObj);
            });
          }
        });
      } catch (err) {
        console.error(err);
      }
    },

    async fixAudiDataCut() {
      this.internalLoading = true;
      const subColRef = collection(
        db,
        "campaigns",
        "campanha-nacional-cuida-mais-brasil",
        "censuresData"
      );
      const querySnapshot = await getDocs(subColRef);
      this.censureData = [];
      this.problemCensures = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, ' => ', doc.data());
        const newCensure = doc.data();
        newCensure.id = doc.id;
        this.censureData.push(newCensure);
        if (this.checkAudioCut(newCensure)) this.problemCensures.push(newCensure);
      });

      if (this.problemCensures.length) {
        console.log("has problems in " + this.problemCensures.length + " censures");
        this.problemCensures.forEach(async (obj) => {
          obj.audioData.cut.inicioMinutos = parseInt(obj.audioData.cut.inicioMinutos);
          obj.audioData.cut.inicioSegundos = parseInt(obj.audioData.cut.inicioSegundos);
          obj.audioData.cut.fimMinutos = parseInt(obj.audioData.cut.fimMinutos);
          obj.audioData.cut.fimSegundos = parseInt(obj.audioData.cut.fimSegundos);
          // update doc in firestore
          const constdocRef = doc(
            db,
            "campaigns",
            "campanha-nacional-cuida-mais-brasil",
            "censuresData",
            obj.id
          );

          await updateDoc(constdocRef, {
            "audioData.cut": obj.audioData.cut,
          });
        });
      } else {
        console.log("All fixed");
      }

      this.internalLoading = false;
    },

    checkAudioCut(obj) {
      if (obj && obj.audioData && obj.audioData.cut) {
        const minStart = obj.audioData.cut.inicioMinutos + "";
        const secStart = obj.audioData.cut.inicioSegundos + "";
        const minEnd = obj.audioData.cut.fimMinutos + "";
        const secEnd = obj.audioData.cut.fimSegundos + "";
        if (minStart.length > 1 && minStart[0] == "0") return true;
        if (secStart.length > 1 && secStart[0] == "0") return true;
        if (minEnd.length > 1 && minEnd[0] == "0") return true;
        if (secEnd.length > 1 && secEnd[0] == "0") return true;
      }
      return false;
    },

    async changeCensureCampaign() {
      const campaignId = "trabalho-analogo-a-escravidao";
      this.internalLoading = true;

      try {
        // const subColRef = doc(db, 'collectionName',  'documentID','subCollectionID', 'subCollectionName');
        const q = query(collection(db, `campaigns/${campaignId}/censuresData`));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (censure) => {
          // console.log(doc.id, " => ", doc.data());
          const censureRef = doc(db, `campaigns/${campaignId}/censuresData`, censure.id);
          await updateDoc(censureRef, {
            campanha: "Combate ao trabalho análogo à escravidão",
            conteudo: "Combate ao trabalho análogo à escravidão",
          });
        });
        this.internalLoading = false;
      } catch (err) {
        this.internalLoading = false;
        console.error(err);
      }
    },
  },
};
</script>

<style scoped>
.dev-panel {
  padding: 20px;
}
</style>
